@use '../../scss/theme';

.root {
  text-align: center;
  position: absolute;
  bottom: -21px;
  width: 100%;

  .toolbar {
    padding: 15px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 42px;

    button {
      border-radius: 42px;
      margin-left: 10px;
      padding: 10px;

      svg {
        width: 20px;
      }

      img {
        width: 20px;
      }

      &.rebuttalFor {
        background-color: theme.$for;
        font-weight: 700;
      }

      &.rebuttalAgainst {
        background-color: theme.$against;
      }
    }
  }
}
