@use '../../scss/theme';
@use '../../scss/breakpoints';

.root {
  height: 500px;
  margin: 8px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  box-shadow: 0 0 5px #ddd;
  padding: 20px;
  @media (max-width: breakpoints.$sm) {
    padding: 15px;
  }

  a {
    text-decoration: none;
  }

  .title {
    font-family: Inter, Helvetica sans-serif;
    margin-top: 20px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    align-items: center;
    color: theme.$text-regular;
    opacity: 0.9;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .body {
    font-family: Inter, Helvetica sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: theme.$text-unselected !important;
    opacity: 0.9;

    p {
      color: theme.$text-unselected !important;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      min-height: 72px;
      overflow: hidden;
    }

    :nth-child(n + 2) {
      display: none;
    }
  }

  .user {
    margin-top: 20px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 3px;
      margin-right: 7px;
      object-fit: cover;
    }

    .name {
      color: theme.$text-regular;
      font-size: 16px;
      font-weight: 400;
      align-self: center;
    }
  }

  .date {
    margin-left: auto;

    img {
      width: 13px !important;
      height: 13.28px !important;
      color: theme.$text-unselected;
      align-self: center;
      margin-right: 3px;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      color: theme.$text-unselected;
      align-self: center;
    }
  }

  .counterNumber {
    margin-top: 20px;

    img {
      width: 17px;
      color: theme.$text-unselected;
      align-self: center;
      margin-right: 5px;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      color: theme.$text-unselected;
      align-self: center;
    }
  }
}
