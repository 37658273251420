@use '../../scss/breakpoints';
@use '../../scss/theme';

.root {
  margin: 10px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  box-shadow: 0 0 5px #ddd;
  padding: 20px;
  @media (max-width: breakpoints.$sm) {
    padding: 15px;
  }

  a {
    text-decoration: none;
    color: theme.$primary;
  }

  .title {
    img {
      width: 55px;
      height: 55px;
      border-radius: 4px;
      margin-right: 10px;
      object-fit: cover;
      @media (max-width: breakpoints.$sm) {
        width: 30px;
        height: 30px;
        border-radius: 100px;
      }
    }

    p:nth-child(1) {
      font-family: Inter, Helvetica sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: theme.$text-regular;
      opacity: 0.9;
      margin: 0;
      margin-bottom: 10px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &:hover {
        -webkit-line-clamp: unset;
      }
    }
  }

  .body {
    font-family: Inter, Helvetica sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    margin-top: 24px;
    color: theme.$text-unselected !important;
    opacity: 0.9;
    p {
      margin: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    img {
      display: none;
    }

    :nth-child(n + 2) {
      display: none;
    }
  }

  .theory {
    font-family: Inter, Helvetica sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: theme.$text-unselected;
    opacity: 0.9;
    margin: 0;
    margin-bottom: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date {
    margin-left: auto;

    svg {
      width: 13px;
      color: theme.$text-unselected;
      align-self: center;
      margin-right: 3px;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      color: theme.$text-unselected;
      align-self: center;
    }
  }

  .counterNumber {
    margin-top: 20px;

    img {
      width: 17px;
      color: theme.$text-unselected;
      align-self: center;
      margin-right: 5px;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      color: theme.$text-unselected;
      align-self: center;
    }
  }
}
