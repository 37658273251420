@use '../scss/theme';

.root {
  .banner {
    position: relative;

    .userAvatar {
      width: 166px;
      height: 166px;
      border-radius: 8px;
      position: absolute;
      bottom: -80px;
      border: 3px solid white;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      .overlay {
        width: 100%;
        height: 100%;

        &:hover {
          opacity: 1;
        }
        opacity: 0;
      }
    }

    .icon {
      position: absolute;
      background: rgba(255, 255, 255, 0.23);
      border: 1px solid rgba(255, 255, 255, 0.21);
      backdrop-filter: blur(4px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 32.5px;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      cursor: pointer;

      img {
        width: 15px;
      }

      &.editIcon {
        top: 10px;
        right: 10px;
      }

      &.cameraIcon {
        bottom: 10px;
        right: 10px;
      }
    }
  }

  .activity-section {
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 8px;
  }

  .filterBtn {
    color: theme.$text-regular;
    opacity: 0.9;
    font-weight: 400;
    font-size: 16px;
    padding: 14px;
    line-height: 24px;

    &.selected {
      color: theme.$primary;

      .dot {
        position: absolute;
        bottom: -3px;
        left: 50%;
        width: 5px;
        height: 5px;
        justify-content: center;
        margin: auto;
        border-radius: 100px;
        background-color: theme.$primary;
      }
    }
  }

  .user-detail {
    text-align: center;
    margin-top: 100px;

    a {
      text-decoration: none;
    }

    .messageBtn {
      display: flex;

      img {
        width: 20px;
        height: 20px;
      }

      div {
        margin-left: 10px;
        align-self: center;
      }
    }

    .displayName {
      color: theme.$text-regular;
      font-size: 32px;
      font-weight: 600;
      line-height: 39px;
    }

    .username {
      color: theme.$text-unselected;
      font-weight: 400;
      font-size: 24px;
      margin-top: 10px;
    }

    .userBio {
      color: theme.$text-unselected;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
    }

    .numbers {
      .number {
        font-size: 22px;
        color: theme.$text-regular;
        opacity: 0.85;
        font-weight: 600;
      }

      .desc {
        color: theme.$text-unselected;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .edit-user-detail {
    margin: 100px 15px 15px;
    padding: 20px;
    border-radius: 4px;
    background-color: #fbfbfb;

    .title {
      font-weight: 400;
      font-size: 16px;
      color: theme.$text-unselected;
      line-height: 22px;
      margin-bottom: 10px;
    }
  }
}

.popWindow {
  .popContent {
    display: flex;
    padding: 10px;
    min-width: 150px;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
      color: theme.$primary;
    }

    img {
      width: 12px;
    }

    span {
      margin-left: 10px;
      color: theme.$text-unselected;
      font-size: 12px;
      font-weight: 400;
      align-self: center;
    }
  }
}
