.break-word,
.break-word * {
  word-break: break-word;
}

body {
  background-color: white !important;
}

.markup-body {
  font-size: 14px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  overflow: hidden !important;
}

.markup-body.clamped {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.markup-body ul {
  list-style-type: disc;
  padding-left: 36px;
}

.markup-body ol {
  list-style-type: decimal;
  padding-left: 36px;
}

.markup-body blockquote {
  margin-inline-start: 2em !important;
  position: relative !important;
  margin: 16px 0 !important;
  padding: 5px 8px 5px 30px !important;
  background: none repeat scroll 0 0 rgba(100, 100, 100, 0.05) !important;
  color: #333 !important;
  border: none !important;
  border-left: 10px solid #d6dbdf !important;
}

.markup-body p,
.markup-body ol,
.markup-body ul,
.markup-body hr {
  margin-bottom: 12px;
}

.markup-body h1,
.markup-body h2,
.markup-body h3,
.markup-body h4,
.markup-body h5,
.markup-body h6 {
  padding: 0;
  border: none;
  font-weight: 700 !important;
}

.markup-body h1 {
  font-size: 26px !important;
  margin: 26px 0 !important;
}

.markup-body h2 {
  font-size: 24px !important;
  margin: 24px 0 !important;
}

.markup-body h3 {
  font-size: 22px !important;
  margin: 22px 0;
}

.markup-body h4 {
  font-size: 20px;
  margin: 20px 0;
}

.markup-body h5 {
  font-size: 18px;
  margin: 18px 0;
}

.markup-body h6 {
  font-size: 16px !important;
  margin: 16px 0;
}

.markup-body a {
  color: #0090ff;
}

.markup-body img {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  max-width: 100% !important;
  max-height: 50vh !important;
}
