.input {
  padding: 5px 15px;
  margin-right: 5px;
  border-radius: 0.375rem;
  border: 1px solid rgb(215, 224, 226);
  font-size: 14px;
  max-height: 80px;
  min-height: 50px;
  outline: none;
  word-break: break-word;

  &:empty {
    height: 50px;
    padding: 11px 15px;
  }

  &:empty:not(:focus):before {
    content: 'Start a new message';
    opacity: 0.7;
    z-index: -1;
  }

  &:focus {
    outline: none;
    border-color: rgb(11, 178, 124);
    border-width: 2px;
  }
}

.root {
  &:global(.MuiTextField-root) {
    width: 100%;
    margin-right: 5px;
    align-self: center;
  }
}
