@use '../../scss/theme';

.root {
  padding: 5px;
  width: 300px;
  cursor: pointer;
  position: relative;

  img {
    height: 44px;
    width: 44px;
    min-width: 44px;
    border-radius: 4px;
    object-fit: cover;
  }

  .dot {
    width: 7px;
    height: 7px;
    z-index: 1;
    background-color: theme.$secondary;
    border-radius: 100%;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .content {
    margin-left: 10px;
  }

  .username {
    background-color: theme.$primary-background;
    padding: 2px 7px;
    color: theme.$primary;
    font-size: 8px;
    border-radius: 2px;
  }

  .createTime {
    font-size: 10px;
    margin-left: 10px;
    // position: absolute;
    // right: 20px;
    align-self: center;

    .svg {
      width: 8px;
      height: 8px;
      color: theme.$text-unselected;
    }

    span {
      margin-left: 2px;
      color: theme.$text-unselected;
      opacity: 0.9;
      font-size: 10px;
    }
  }
}
